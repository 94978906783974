require("leaflet");
require("leaflet-providers");
require("bootstrap/dist/js/bootstrap.js");
require("./canvas/plugins.js");
require("./canvas/plugins/jquery.important.js");
require("./canvas/functions.js");
require('../css/app.less')

require(["jquery"], function ($) {
    $(document).ready(function () {

        $('input[type="file"]').on('change', function () {
            var fileName = $(this).val();
            $(this).next('.custom-file-label').addClass("selected").text(fileName);
        });


        if ($('body').hasClass('contact') && $("#google-map").length > 0) {
            // var map = L.map('google-map').setView([45.926446, 6.167459], 10);
            var map = L.map('google-map').setView([46.169263, 6.391841], 9);
            L.tileLayer.provider('OpenStreetMap.Mapnik').addTo(map);
            L.marker([45.926446, 6.167459])
                .bindPopup("<b>Juris Office Annecy</b><br>15 avenue du pré félin<br/>PAE des Glaisins<br/>Annecy-le-Vieux<br/>74940 ANNECY")
                .addTo(map);
            L.marker([46.371965, 6.477511])
                .bindPopup("<b>Juris Office Thonon-les-Bains</b><br>28 rue Vallon<br/>74205 THONON-LES-BAINS")
                .addTo(map);
        }

        if ($('body').hasClass('etudes') && $("#map-annecy").length > 0) {
            var map = L.map('map-annecy').setView([45.926446, 6.167459], 15);
            L.tileLayer.provider('OpenStreetMap.Mapnik').addTo(map);
            L.marker([45.926446, 6.167459]).addTo(map);
            map.scrollWheelZoom.disable();
        }

        if ($('body').hasClass('etudes') && $("#map-thonon").length > 0) {
            var map = L.map('map-thonon').setView([46.37196540350392,6.477511874028768], 15);
            L.tileLayer.provider('OpenStreetMap.Mapnik').addTo(map);
            L.marker([46.37196540350392, 6.477511874028768]).addTo(map);
            map.scrollWheelZoom.disable();
        }

        if ($('body').hasClass('competence')) {
            var mapInt = L.map('google-map-int').setView([46.6027, 1.8752], 5);
            L.tileLayer.provider('OpenStreetMap.Mapnik').addTo(mapInt);
            mapInt.scrollWheelZoom.disable();

            var map = L.map('google-map-locale').setView([45.6745, 6.3906], 8);
            L.tileLayer.provider('OpenStreetMap.Mapnik').addTo(map);
            map.scrollWheelZoom.disable();

            var geojson;

            geojson = L.geoJson(document.AREAMAP, {
                style: styleAreaMap,
                onEachFeature: onEachFeature
            }).addTo(map);


            function styleAreaMap(feature) {
                return {
                    weight: 3,
                    opacity: 1,
                    color: "#db3747",
                    dashArray: '3',
                    fillOpacity: 0.2,
                    fillColor: "#db3747"
                };
            }

            function onEachFeature(feature, layer) {
                layer.on({
                    mouseover: highlightFeature,
                    mouseout: resetHighlight,
                    click: zoomToFeature
                });
            }

            function highlightFeature(e) {
                var layer = e.target;

                layer.setStyle({
                    weight: 3,
                    opacity: 1,
                    color: "#d5b15f",
                    dashArray: '3',
                    fillOpacity: 0.2,
                    fillColor: "#d5b15f"
                });

                if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
                    layer.bringToFront();
                }

                info.update(layer.feature.code);
            }

            function resetHighlight(e) {
                geojson.resetStyle(e.target);
                info.update();
            }

            function zoomToFeature(e) {
                map.fitBounds(e.target.getBounds());
            }


        }

        if ($('body').hasClass('depot-acte')) {
            var $collectionHolder;

            // Get the ul that holds the collection of tags
            $collectionHolder = $('ul.files-collection');
            $collectionHolder.data('index', $collectionHolder.find(':input').length);

            $('#addFile').on('click', function (e) {
                addTagForm($collectionHolder);
            });
        }

        $(':input[data-trigger-item]').on('change', function (e) {
            var input = $('[name*="' + $(this).attr('data-trigger-item') + '"]');
            var parent = input.parents('.form-group');

            if ($(this).val() == $(this).attr('data-trigger-value')) {
                input.prop("required", "required");
                parent.find('label').addClass("required");
                parent.show();
            } else {
                input.removeProp("required");
                parent.find('label').removeClass("required");
                parent.hide();
            }
        });
        $(':input[data-trigger-item]').trigger('change');
    });


    function addTagForm($collectionHolder) {
        // Get the data-prototype explained earlier
        var prototype = $collectionHolder.data('prototype');

        // get the new index
        var index = $collectionHolder.data('index');

        var newForm = prototype;
        // You need this only if you didn't set 'label' => false in your tags field in TaskType
        // Replace '__name__label__' in the prototype's HTML to
        // instead be a number based on how many items we have
        // newForm = newForm.replace(/__name__label__/g, index);

        // Replace '__name__' in the prototype's HTML to
        // instead be a number based on how many items we have
        newForm = newForm.replace(/__name__/g, index);

        // increase the index with one for the next item
        $collectionHolder.data('index', index + 1);

        // Display the form in the page in an li, before the "Add a tag" link li
        var $newFormLi = $('<li></li>').append(newForm);
        $collectionHolder.find('li:last').before($newFormLi);
    }
});
